.gender-radio-form-element {
  margin: 1rem auto;

  .gender-radio-form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .ant-radio-button-wrapper {
      width: 100%;
      max-width: 14rem;
      flex: 1 1 auto;
      min-height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      padding: 0rem 0.5rem;
      border-radius: 1rem;
      border: 1px solid @border-color-base;

      &::before {
        content: unset;
      }
    }
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .gender-radio-form-element.mobile-layout {
    .gender-radio-form-group {
      flex-direction: column;

      .ant-radio-button-wrapper:first-child {
        margin-bottom: 1rem;
      }
    }
  }
}