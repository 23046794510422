.workflow-record-opinion-processing {
  text-align: center;
  padding: 20rem 3rem;
}

@media only screen and (max-width: @screen-threshold-vertical) {
  .workflow-record-opinion-processing {
    padding: 16rem 3rem;
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .workflow-record-opinion-processing {
    padding: 12rem 3rem;
  }
}