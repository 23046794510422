@import './warmupTestCt.less';
@import './recordOpinionCt.less';
@import './goodbye.less';
@import './terminate.less';

.default-workflow-form-container {
  width: 100%;
  max-width: @workflow-max-width-default;
  margin: 1rem auto;
  padding: 2.5rem;
}

.large-workflow-form-container {
  width: 100%;
  max-width: @workflow-max-width-large;
  margin: 1rem auto;
  padding: 2.5rem;
}

.small-workflow-form-container {
  width: 100%;
  max-width: @workflow-max-width-small;
  margin: 1rem auto;
  padding: 2.5rem;
}

.split-workflow-form-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  width: 100%;

  .split-workflow-form-container-content {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .banner-layout {
    .split-workflow-form-container-content {
      min-height: calc(100vh - 13rem);
    }
  }

  .split-workflow-form-container-content-container {
    padding: 2rem;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .split-workflow-form-container-empty {
    width: 50%;
  }

  .split-workflow-form-container-filled {
    background-color: @primary-color;
    width: 50%;

    .workflow-activity-title,
    .workflow-activity-subtitle,
    .workflow-activity-subtitle-sm,
    .ant-form-item-label label,
    .ant-radio-wrapper,
    .nps-radio-form-labels,
    .scale-slider-form-labels {
      color: @text-color-light;
    }

    .ant-btn-primary {
      border-color: @text-color-light;
      background-color: @text-color-light;
      color: @primary-color;
    }

    .carousel-arrow {
      color: @text-color-light;

      &:hover {
        color: lighten(@text-color-light, 10%);
      }

      &:active {
        color: darken(@text-color-light, 20%);
      }
    }
  }

  &.focus-filled {
    .split-workflow-form-container-empty {
      width: 33%;
    }

    .split-workflow-form-container-filled {
      width: 67%;
    }
  }

  &.focus-empty {
    .split-workflow-form-container-empty {
      width: 67%;
    }

    .split-workflow-form-container-filled {
      width: 33%;
    }
  }
}

.workflow-activity-title {
  text-align: center;
  color: @primary-color;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.workflow-activity-test-advance-retreat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workflow-activity-subtitle {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.workflow-activity-subtitle-sm {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 1.25rem;
}

.workflow-auto-submit-spin {
  z-index: @zindex-tooltip;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: @screen-threshold-widescreen) {

  .default-workflow-form-container,
  .large-workflow-form-container,
  .small-workflow-form-container {
    padding: 2rem;
  }

  .split-workflow-form-container {
    &.focus-filled {
      .split-workflow-form-container-empty {
        width: 40%;
      }

      .split-workflow-form-container-filled {
        width: 60%;
      }
    }

    &.focus-empty {
      .split-workflow-form-container-empty {
        width: 60%;
      }

      .split-workflow-form-container-filled {
        width: 40%;
      }
    }
  }
}

@media only screen and (max-width: @screen-threshold-vertical) {

  .default-workflow-form-container,
  .large-workflow-form-container,
  .small-workflow-form-container {
    padding: 1.5rem;
  }

  .split-workflow-form-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .split-workflow-form-container-content {
      min-height: auto;
    }

    .banner-layout {
      .split-workflow-form-container-content {
        min-height: calc(50vh - 13rem);
      }
    }

    .split-workflow-form-container-content-container {
      padding: 2rem 0.5rem;
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 50vh;
    }

    .split-workflow-form-container-empty {
      width: 100%;
    }

    .split-workflow-form-container-filled {
      background-color: @primary-color;
      width: 100%;

      .workflow-activity-title,
      .workflow-activity-subtitle,
      .ant-form-item-label label,
      .ant-radio-wrapper {
        color: @text-color-light;
      }

      .ant-btn-primary {
        background-color: @text-color-light;
        color: @primary-color;
      }
    }

    &.focus-filled {
      .split-workflow-form-container-empty {
        width: 100%;
        min-height: 35vh;
      }

      .split-workflow-form-container-filled {
        width: 100%;
        min-height: 60vh;
      }
    }

    &.focus-empty {
      .split-workflow-form-container-empty {
        width: 100%;
        min-height: 60vh;
      }

      .split-workflow-form-container-filled {
        width: 100%;
        min-height: 35vh;
      }
    }
  }
}


@media only screen and (max-width: @screen-threshold-mobile) {

  .default-workflow-form-container,
  .large-workflow-form-container,
  .small-workflow-form-container {
    padding: 1rem;
  }

  .workflow-activity-title {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }

  .workflow-activity-subtitle {
    font-size: 1.25rem;
    margin-bottom: 0rem;
  }
}