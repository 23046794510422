.carousel-arrow {
  font-size: 1rem;
  padding: 0.5rem;
  transition: color 0.3s ease-in-out;
  background-color: @primary-color;
  color: @text-color-light;
  border-radius: 50%;
}

.ant-carousel {
  .with-arrows {
    padding: 0rem 2rem;
  }

  .slick-prev,
  .slick-next {
    width: 2rem;
    height: 2rem;
    color: unset;
    font-size: unset;
    margin-top: -1rem;

    &::before {
      content: unset;
    }
  }

  .slick-prev {
    left: 0.2rem;
    z-index: 10;
  }
  .slick-next {
    right: 0.2rem;
  }
}

.ant-carousel {
  .slick-prev:hover,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-next:focus {
    color: unset;
  }
}

.workflow-element-carousel {
  width: 100%;
}

.workflow-element-carousel-slide {
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .workflow-element-image {
    height: 100%;
    display: flex;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@media only screen and (max-width: @screen-threshold-widescreen) {
  .workflow-element-carousel-slide {
    min-height: 32rem;
  }
}

@media only screen and (max-width: @screen-threshold-vertical) {
  .workflow-element-carousel-slide {
    min-height: 26rem;
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .workflow-element-carousel-slide {
    min-height: 16rem;
  }
}