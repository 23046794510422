.required-viewing-block {
  padding: 2rem;
  background-color: #FFF;
  color: @text-color;
  border: 1px solid @border-color-base;
}

.required-viewing-block-text {
  text-align: center;
  margin-top: 2rem;
}

.required-viewing-block-image-container {
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center;    /* vertically center */
  height: 30rem;
  width: 100%;
}

.required-viewing-block-image {
  max-width: 100%;
  height: 29rem;
  object-fit: contain;
}