.wistia-external-video-workflow-element {
  height: 40rem;
  width: 100%;
  max-width: 65rem;
  margin: 0rem auto;
}

.vimeo-external-video-workflow-element {
  iframe {
    height: 40rem;
    width: 100%;
  }
}

.qualie-external-video-workflow-element {
  height: 40rem;
  max-width: 65rem;
  margin: 0rem auto;
  width: 100%;

  .qualie-video-player {
    width: 100%;
    height: 100%;

    .vjs-control-bar {
      .vjs-current-time,
      .vjs-time-divider,
      .vjs-duration {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: @screen-threshold-widescreen) {
  .wistia-external-video-workflow-element {
    height: 32rem;
    max-width: 55rem;
  }

  .vimeo-external-video-workflow-element {
    iframe {
      height: 32rem;
    }
  }

  .qualie-external-video-workflow-element {
    height: 32rem;
    max-width: 55rem;
  }
}

@media only screen and (max-width: @screen-threshold-vertical) {
  .wistia-external-video-workflow-element {
    height: 30rem;
    max-width: 50rem;
  }

  .vimeo-external-video-workflow-element {
    iframe {
      height: 30rem;
    }
  }

  .qualie-external-video-workflow-element {
    height: 30rem;
    max-width: 50rem;
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .wistia-external-video-workflow-element {
    height: 20rem;
    max-width: 30rem;
  }

  .vimeo-external-video-workflow-element {
    iframe {
      height: 20rem;
    }
  }

  .qualie-external-video-workflow-element {
    height: 20rem;
    max-width: 30rem;
  }
}