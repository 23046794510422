.content-reminder-banner {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  color: @text-color-light;
  min-height: 9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  padding-left: 1rem;

  .content-reminder-banner-element {
    padding-right: 1rem;

    .content-reminder-banner-element-content {
      min-width: 12rem;
      min-height: 7rem;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 0.25rem;

      .ant-btn-background-ghost {
        padding: 0;
        height: 2rem;
        border-radius: 0;

        .anticon {
          font-size: 2rem;
        }
      }
    }
  }

  &.mobile-layout {
    min-height: unset;
    border-bottom: none;
    padding-left: 0;

    .content-reminder-banner-collapse {
      width: 100%;

      .ant-collapse-item {
        width: 100%;

        .ant-collapse-header {
          justify-content: center;
        }

        .content-reminder-banner-element {
          padding-right: 0;

          .content-reminder-banner-element-content {
            min-height: 10rem;
            margin: 0 0 1rem 0;

            .ant-btn {
              height: 3rem;
            }
          }
        }
      }
    }
  }
}

.content-banner-overlay-wrapper {
  max-width: 86rem;

  &.mobile-layout {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      padding: 3.5rem 0 0.5rem 0;

      .ant-carousel {
        .slick-prev {
          left: 9rem;
        }

        .slick-next {
          right: 9rem;
        }

        .slick-prev,
        .slick-next {
          top: 95%;
        }

        .with-arrows {
          padding: 0 0 3rem 0;
        }
      }
    }
  }
}