@import './carousel.less';
@import './initiative.less';
@import './cameraTag/index.less';
@import './image.less';
@import './form/index.less';
@import './externalVideo/index.less';
@import './reviewQuestionV2/';
@import './contentBanner.less';
@import './qualieCamera/index.less';
@import './details.less';
@import './reviewImage/';
@import './uploadButtonContainer.less';

.workflow-element-message-content {
  min-height: 30vh;
  padding: 10rem 0;
  text-align: center;
  font-size: 3rem;
}