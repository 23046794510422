.qualie-camera-sound-waveform {
  position: relative;
  background: rgba(0, 0, 0, 0.1);

  &.qualie-camera-sound-waveform-horizontal {
    width: 100%;
    height: 0.5rem;

    .qualie-camera-sound-waveform-bar {
      top: 0px;
      left: 0px;
      height: 100%;
    }
  }

  &.qualie-camera-sound-waveform-vertical {
    width: 0.5rem;
    height: 100%;

    .qualie-camera-sound-waveform-bar {
      bottom: 0px;
      left: 0px;
      width: 100%;
    }
  }
}

.qualie-camera-sound-waveform-bar {
  position: absolute;
  background-color: @primary-color;
}