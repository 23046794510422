@import './vendor/antd.less';
@import './vendor/animate.less';
@import './vendor/cameratag.less';
@import './vendor/qualieCommonReact.less';
@import './variables.less';
@import './content.less';
@import './modules/pux/index.less';

.ant-form-item-label {
  text-align: unset !important;
}