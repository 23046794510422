.root-layout {
  min-height: 100vh;

  & > .ant-layout-content {
    min-height: 100vh;
  }
  
  &.rtl {
    direction: rtl;
  }
}
