.video-rating-radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
  .ant-radio-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    min-height: 12rem;
    flex: 1 1 33%;
    text-align: center;
    min-width: 18rem;
    padding: 1rem 0.75rem;
  }
}

.video-rating-radio-icon {
  color: @primary-color;
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.video-rating-radio-title {
  font-size: 1.4rem;
  font-weight: 600;
}