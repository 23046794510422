.qualie-camera-record-progress {
  width: 100%;
  height: 1rem;
  white-space: nowrap;
  position: relative;
  line-height: 0;
}

.qualie-camera-record-progress-bar-back-min {
  background-color: fade(@warning-color, 25%);
  display: inline-block;
  height: 100%;
}

.qualie-camera-record-progress-bar-back {
  background-color: fade(@success-color, 25%);
  display: inline-block;
  height: 100%;
}

.qualie-camera-record-progress-bar {
  background-color: @success-color;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: background-color 0.25s ease-in-out, width 0.25s ease-in-out;

  &.too-short {
    background-color: @warning-color;
  }
}