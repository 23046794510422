.global-progress {
  line-height: 1;
  font-size: 8px;
  margin-top: -1px;
  .ant-progress-outer {
    .ant-progress-inner {
      border-radius: 0px;
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
}