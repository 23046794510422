.preference-opinion-form-element {
  .preference-container-wrapper {
    .preference-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: @workflow-background;
      box-sizing: border-box;

      .preference-rating {
        align-self: stretch;
        display: flex;
        align-items: center;
        color: @text-color-light;

        .ant-row.ant-form-item {
          margin: 0;
        }

        .preference-rating-wrapper {
          box-sizing: border-box;

          .ant-select-selector {
            border: none;
            background: transparent;
          }
        }
      }

      .preference-stimulus-wrapper {
        flex: 0 0 25%;

        .preference-stimulus-link {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          min-height: 10rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;

          .preference-view-link {
            padding: 0.25rem;

            .ant-btn-background-ghost {
              padding: 0;
              height: 2rem;
              border-radius: 0;

              .anticon {
                font-size: 2rem;
              }
            }
          }
        }
      }

      .preference-initiative-text {
        flex: 1 1 60%;
        box-sizing: border-box;
        width: 100%;
        margin-left: 1rem;

        p {
          font-size: 1.125rem;
          font-weight: 500;
          line-height: 28px;
          font-family: Poppins, sans-serif;
          color: @text-color;
          margin: 0;
        }

        .prerequisite-error {
          background-color: @workflow-background;
          border: none;
          padding: 0;

          .ant-alert-message {
            color: @error-color;
            font-family: Poppins, sans-serif;
          }
        }
      }

      .preference-drag-drop-toggle {
        flex: 0 0 4%;
        font-size: 2.5rem;
      }
    }

    .ant-form-item-explain-error {
      display: none;
    }
  }

  &.mobile-layout {
    .preference-container {
      flex-direction: column;

      .preference-rating {
        .ant-row.ant-form-item {
          width: 100%;
        }

        .preference-rating-wrapper {
          .ant-select-selector {
            .ant-select-selection-item {
              text-align: center;
              padding-left: 23px;
            }
          }
        }
      }

      .preference-stimulus-wrapper {
        width: 100%;
      }

      .preference-initiative-text {
        margin: 0;
        padding: 1rem;

        .prerequisite-error {
          padding: 0;
          margin: 0;
        }
      }

      .preference-drag-drop-toggle {
        display: none;
      }
    }
  }

  .ant-form-item-explain-connected {
    .ant-form-item-explain-error {
      display: none;
    }
  }
}

.preference-stimuli-overlay-wrapper {
  max-width: 86rem;

  &.mobile-layout {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      padding: 3.5rem 0 0.5rem 0;

      .ant-carousel {
        .slick-prev {
          left: 9rem;
        }

        .slick-next {
          right: 9rem;
        }

        .slick-prev,
        .slick-next {
          top: 95%;

        }

        .with-arrows {
          padding: 0 0 3rem 0;
        }
      }
    }
  }
}