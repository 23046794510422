.qualie-camera-guidelines {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .qualie-camera-guideline {
    position: absolute;

    &:nth-child(1) {
      border-left: 2px solid rgba(255, 255, 255, 0.2);
      height: 100%;
      left: 25%;
    }

    &:nth-child(2) {
      border-left: 2px solid rgba(255, 255, 255, 0.2);
      height: 100%;
      left: 75%;
    }

    &:nth-child(3) {
      border-top: 2px solid rgba(255, 255, 255, 0.2);
      width: 100%;
      top: 30%;
    }

    &:nth-child(4) {
      border-top: 2px solid rgba(255, 255, 255, 0.2);
      width: 100%;
      top: 75%;
    }
  }
}