.nps-radio-form-element {
  max-width: 46rem;
  margin: 1rem auto 2rem;
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .ant-radio-button-wrapper {
      width: 100%;
      max-width: 14rem;
      flex: 1 1 auto;
      min-height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      padding: 0rem 0.5rem;
      &.ant-radio-button-wrapper-checked {
        background-color: @primary-color;
        color: @text-color-light
      }
    }
  }
}

.nps-radio-form-labels {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .nps-radio-form-element.oversized {
    .ant-radio-group {
      flex-direction: column;
    }
  }
}