@import './recordProgress.less';

.camera-tag-overlay-actions {
  // initially no display, camera tag will override this once it is loaded.
  display: none;
}

.camera-tag-overlay-actions-inner {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ant-btn-primary {
    color: @text-color-light;
    background: @primary-color;
  }

  .ant-spin-lg {
    .ant-spin-dot {
      font-size: 4rem;
      .ant-spin-dot-item {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
