@import './recordProgress.less';
@import './soundWaveform.less';
@import './guidelines.less';

.qualie-camera-workflow-element {
  max-width: 40rem;
  width: 100%;
  margin: auto;
  height: 100%;
  background: @black-color;
  position: relative;
  overflow: hidden;
  max-height: 80vh;

  &:not(.preview) {
    .qualie-camera-workflow-element-recording-preview {
      display: none;
    }
  }

  &.flip-video {

    .qualie-camera-workflow-element-live-preview,
    .qualie-camera-workflow-element-recording-preview {
      video {
        transform: scaleX(-1);
      }
    }
  }
}

.qualie-camera-workflow-element-container {
  width: 100%;
  padding-top: 177.77778%;
}

.qualie-camera-workflow-element-live-preview,
.qualie-camera-workflow-element-recording-preview {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }

  .ant-progress {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    font-size: 1px;
    line-height: 1px;

    .ant-progress-inner {
      border-radius: 0px;
      background: transparent;
    }

    .ant-progress-bg {
      border-radius: 0px;
      background: @white-color;
    }
  }
}

.qualie-camera-workflow-element-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 100%);
}

.qualie-camera-workflow-element-overlay-ready,
.qualie-camera-workflow-element-overlay-recording {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  max-height: 100%;
  padding-bottom: 4rem;
  text-align: center;
  box-sizing: border-box;
}

.qualie-camera-workflow-element-overlay-recording {
  .qualie-camera-record-progress {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
}

.qualie-camera-workflow-element-overlay-ready-toolbar,
.qualie-camera-workflow-element-overlay-preview-toolbar {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 100%;
  max-height: 100%;
  text-align: right;
}

.qualie-camera-workflow-element-device-configuration,
.qualie-camera-workflow-element-overlay-error {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: fade(@white-color, 80%);
  padding: 1rem;

  .ant-form {
    width: 100%;
    max-width: 16rem;
  }

  button[type=submit] {
    width: 100%;
  }
}

.qualie-camera-workflow-element-overlay-error-content {
  max-width: 20rem;
  padding: 2rem;
  background: @white-color;
  font-size: 1rem;
  text-align: center;
  max-height: 100%;
  overflow-y: auto;

  .anticon {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: @warning-color;
  }

  p {
    text-align: left;
  }
}

.qualie-camera-workflow-element-overlay-recording-complete {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-btn {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
}

.qualie-camera-workflow-element-overlay-upload-progress {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

  .ant-progress-text {
    color: @text-color-light;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
}

.qualie-camera-workflow-element-overlay-countdown {
  position: absolute;
  top: calc(50% - 3rem);
  left: 0px;
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-weight: 500;
  color: @text-color-light;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.qualie-camera-worfklow-element-remaining-time {
  color: @text-color-light;
  font-size: 1.5rem;
  font-family: monospace, sans-serif;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.qualie-camera-workflow-element-overlay-recording-blink {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 1rem;
  animation-name: video-recording-blink;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 2s;

  &::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: @record-color;
    border-radius: 50%;
  }
}

.qualie-camera-workflow-element-recording-action {
  cursor: pointer;

  &.record {
    background: transparent;
    border: none;
    border: 3px solid @record-color;
    padding: 3px;
    border-radius: 50%;
    line-height: 1px;
    width: 3rem;
    height: 3rem;

    &::before {
      content: "";
      display: inline-block;
      line-height: 1px;
      width: 100%;
      height: 100%;
      background: @record-color;
      border-radius: 50%;
    }
  }

  &.stop {
    border: none;
    line-height: 1px;
    width: 3rem;
    height: 3rem;
    background: @record-color;
    border-radius: 4px;

    &::before {
      content: "";
      display: inline-block;
    }
  }

  &.upload,
  &.reset {
    position: relative;
    min-width: 14rem;

    .anticon {
      position: absolute;
      left: 1rem;
      top: calc(50% - 0.6rem);
    }
  }

  &.devices,
  &.cancel {
    padding: 0px;
    border: none;
    line-height: 1px;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    color: @white-color;
    background: fade(@black-color, 50%);
  }
}

.qualie-camera-silence-notification-content {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

@keyframes video-recording-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: @screen-threshold-vertical) {
  .qualie-camera-workflow-element {
    &.active {
      z-index: 1;
      position: fixed;
      width: 100%;
      height: 100%;
      max-width: unset;
      max-height: unset;
      top: 0px;
      left: 0px;
    }
  }

  .qualie-camera-workflow-element-overlay-error-content {
    padding: 1rem;
    font-size: 0.8rem;

    .anticon {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
}