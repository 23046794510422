.scale-form-element {
  margin-bottom: 2rem;
  .ant-slider {
    height: 0.75rem;
  }

  .ant-slider-rail {
    height: 0.75rem;
    background: rgba(207, 207, 207, 0.342);
  }

  .ant-slider:hover .ant-slider-rail {
    background: rgba(224, 224, 224, 0.534);
  }

  .ant-slider-track {
    height: 0.75rem;
  }

  .ant-slider-step {
    height: 0.75rem;

    .ant-slider-dot {
      height: 1rem;
      width: 1rem;
    }

    .ant-slider-dot-active {
      border-color: rgba(224, 224, 224, 0.534);
    }
  }

  .ant-slider-handle {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -0.4rem;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-radio-button-wrapper {
      width: 100%;
      max-width: 14rem;
      flex: 1 1 auto;
      min-height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;

      &.ant-radio-button-wrapper-checked {
        background-color: @primary-color;
        color: @text-color-light;
      }
    }
  }

  &.star-rating {
    &.oversized {
      .ant-radio-group {
        .ant-radio-button-wrapper {
          &.star-rating-star {
            font-size: 3rem;
          }
        }
      }
    }

    .ant-radio-group {
      justify-content: center;

      &:hover>.star-rating-star {
        .anticon-star {
          color: gold;
        }
      }

      &>.star-rating-star:hover~.star-rating-star {
        .anticon-star {
          color: @text-color;
        }
      }

      .ant-radio-button-wrapper {
        width: auto;
        flex: 0 1 auto;

        &:not(:first-child)::before {
          background-color: @workflow-background;
        }

        &.star-rating-star {
          border: none;
          font-size: 4rem;

          &:focus-within {
            box-shadow: none;
          }

          .anticon-star.star-filled {
            color: gold;
          }

          &.ant-radio-button-wrapper-checked {
            background-color: @workflow-background;
          }
        }
      }
    }
  }
}

.scale-form-labels {
  display: flex;
  justify-content: space-between;

  .scale-form-label-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;

    .scale-form-label-text {
      background-color: @primary-color;
      color: @text-color-light;
      padding: 0.5rem 1rem;
      border-radius: 5px;
    }

    .scale-form-label-arrow {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid @primary-color;
    }

    &.label-start {
      text-align: left;

      .scale-form-label-arrow {
        align-self: flex-start;
        margin-left: 1.5rem;
      }
    }

    &.label-end {
      text-align: right;

      .scale-form-label-arrow {
        align-self: flex-end;
        margin-right: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .scale-form-element {
    &.oversized {
      .ant-radio-group {
        flex-direction: column;
      }

      .ant-radio-button-wrapper {
        height: 100%;

        &:first-child,
        &:last-child {
          font-size: 1rem;
          line-height: 1.3;
          padding: 0.5rem;
        }
      }
    }

    &.star-rating {
      .ant-radio-group {
        .ant-radio-button-wrapper {
          &.star-rating-star {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}