.incentive-code {
  color: @text-color;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: stretch;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  .ant-checkbox-wrapper {
    color: @text-color;
    vertical-align: middle;
  }

  .ant-form-item-control-input {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
  }

  .incentive-code-value {
    color: @text-color;
    text-align: center;
    padding: 1.5rem 1.5rem;
    font-weight: 600;
    font-size: 1.25rem;
    display: inline-block;
    border: 2px dashed @primary-color;
    word-break: break-all;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
  }
}

.incentive-code-title {
  text-align: center;
}

.incentive-code-end {
  color: @primary-color;

  svg.incentive-code-end-tab {
    display: block;
    height: 100%;
    width: 2rem;
    background: rgba(0, 0, 0, 0.04);
  }
}

.incentive-code-content {
  flex: 1 1 auto;
  padding: 2rem 2rem 1rem 2rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.incentive-code-value-container {
  text-align: center;
  margin: 1rem 0rem;
}

.incentive-code-value-inner {
  display: inline-block;
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 3px;
}

.incentive-code-value-download {
  .ant-btn {
    border: none;
    width: 100%;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.9);
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .incentive-code {
    .incentive-code-content {
      font-size: 0.9rem;
    }

    .incentive-code-value {
      padding: 0.75rem 0.75rem;
      font-size: 1rem;
    }

    .ant-checkbox-wrapper {
      font-size: 0.9rem;
    }
  }

  .incentive-code-end {
    svg.incentive-code-end-tab {
      width: 1.5rem;
    }
  }
}