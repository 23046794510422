.scale-slider-form-element {
  margin: 1rem auto 0rem auto;
  .ant-slider {
    height: 0.75rem;
  }
  .ant-slider-rail {
    height: 0.75rem;
    background: rgba(207, 207, 207, 0.342);
  }
  .ant-slider:hover .ant-slider-rail {
    background: rgba(224, 224, 224, 0.534);
  }
  .ant-slider-track {
    height: 0.75rem;
  }
  .ant-slider-step {
    height: 0.75rem;

    .ant-slider-dot {
      height: 1rem;
      width: 1rem;
    }

    .ant-slider-dot-active {
      border-color: rgba(224, 224, 224, 0.534);
    }
  }
  .ant-slider-handle {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -0.4rem;
  }
}

.scale-slider-form-labels {
  display: flex;
  justify-content: space-between;

  .scale-slider-form-label {
    max-width: 8rem;

    &.slider-label-left {
      text-align: left;

      .rtl & {
        text-align: right;
      }
    }

    &.slider-label-right {
      text-align: right;

      .rtl & {
        text-align: left;
      }
    }
  }
}

.scale-form-label {
  
  &.slider-label-left {
    text-align: left;

    .rtl & {
      text-align: right;
    }
  }

  &.slider-label-right {
    text-align: right;

    .rtl & {
      text-align: left;
    }
  }
}
