.rating-measure-radio-form-element {
  max-width: 40rem;
  margin: 1rem auto 0rem auto;
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .ant-radio-button-wrapper {
      width: 100%;
      max-width: 14rem;
      flex: 1 1 auto;
      min-height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      padding: 0rem 0.5rem;
      &.ant-radio-button-wrapper-checked {
        background-color: @primary-color;
        color: @text-color-light
      }
    }
  }
}
