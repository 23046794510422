.workflow-element-initiative {
  width: 100%;
}

.workflow-element-initiative-slide {
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .workflow-element-image {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@media only screen and (max-width: @screen-threshold-widescreen) {
  .workflow-element-initiative-slide {
    min-height: 32rem;
  }
}

@media only screen and (max-width: @screen-threshold-vertical) {
  .workflow-element-initiative-slide {
    min-height: 28rem;
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .workflow-element-initiative-slide {
    min-height: 16rem;
  }
}