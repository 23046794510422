@import './videoRatingRadio.less';
@import './textRatingRadio.less';
@import './imageRatingRadio.less';
@import './scale.less';
@import './npsRadio.less';
@import './scaleSlider.less';
@import './thermometer.less';
@import './matrix.less';
@import './ratingMeasureRadio.less';
@import './preferenceOpinion.less';
@import './preferenceSingleOpinion.less';
@import './genderRadio.less';
@import './rankingOpinion.less';
@import './text.less';
@import './buttonCheckbox.less';
@import './radio.less';
@import './checkbox.less';

.form-actions {
  text-align: center;
  margin-top: 2rem;
}

.ant-radio-group {
  width: 100%;

  .ant-space {
    width: 100%;

    .ant-radio-wrapper-in-form-item {
      display: flex;
      flex-direction: row;

      span:not(.ant-radio) {
        flex: 1 1 auto;
      }
    }
  }
}

.ant-upload-drag {
  margin-bottom: 2rem;
  padding: 1rem;
}

.ant-upload-list-text-container {
  margin-bottom: 2rem;
  padding: 1rem;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 1.1rem !important;
}

.ant-upload-list {
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center;   
}

.ant-upload-list-picture-card-container {
  width: 300px;
  height: 300px;
}

.form-element-checkbox-group {
  display: flex;
  flex-direction: column;

  .form-element-checkbox {
    display: flex;
    flex-direction: row;
    margin-left: 0;

    span:not(.ant-checkbox) {
      flex: 1 1 auto;
    }
  }
}

.form-element-radio-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form-element-radio-button {
    margin: 0.5rem;
    flex: 1 1 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    text-align: center;
    border-radius: 2rem !important;

    &.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item:not(.ant-radio-button-wrapper-checked) {
      background: @white-color;
      border: 1px solid @text-color;
      color: @text-color;
    }

    .form-element-radio-other-input {
      width: 18rem;
    }

    &.ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }
  }
}

.form-element-radio-button-image-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -7.5rem;
  margin-right: -7.5rem;
  width: auto;

  .form-element-radio-button-image {
    margin: 0.5rem;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 15rem;
    text-align: center;
    border-radius: 0rem !important;

    &.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item:not(.ant-radio-button-wrapper-checked) {
      background: @white-color;
      border: 1px solid @text-color;
      color: @text-color;
    }

    .form-element-radio-other-input {
      width: 18rem;
    }

    &.ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }

    .form-element-radio-button-image-link {
      min-width: 12rem;
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      cursor: pointer;
      min-height: 10rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    
    .form-element-radio-button-image-label {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 12rem;
      max-height: 4rem;
      overflow-y: auto;
    }

    .form-element-radio-button-image-link-fullscreen {
      border-radius: 0;
      padding: 0 0.5rem;
      height: auto;
      background: rgba(194, 197, 204, 0.7);
      color: black;
      border-color: black;
    }
  }
}

.form-element-checkbox-button-image-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -7.5rem;
  margin-right: -7.5rem;
  width: auto;

  .image-checkbox-form-element-btn {
    margin: 0.25rem;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    min-height: 15rem;
    text-align: center;
    border-radius: 0rem !important;
    padding-left: 1rem;
    padding-right: 1rem;

    .form-element-checkbox-button-image-link {
      min-width: 12rem;
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      cursor: pointer;
      min-height: 10rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    
    .form-element-checkbox-button-image-label {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 12rem;
      max-height: 4rem;
      overflow-y: auto;
    }

    .form-element-checkbox-button-image-link-fullscreen {
      border-radius: 0;
      padding: 0 0.5rem;
      height: auto;
      background: rgba(194, 197, 204, 0.7);
      color: black;
      border-color: black;
    }
  }
}

.image-modal-overlay-wrapper {
  max-width: 86rem;

  .ant-modal-content {
    .ant-modal-body {
      text-align: center;
    }
  }
}

.form-element-checkbox-other-input,
.form-element-radio-other-input {
  max-width: 36rem;
  width: 100%;
}

@media only screen and (max-width: @screen-threshold-mobile) {

  .form-element-checkbox-other-input,
  .form-element-radio-other-input {
    width: 100%;
  }

  .form-element-radio-button-group {
    flex-direction: column;
  }

  .form-element-radio-button-image-group {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .form-element-checkbox-button-image-group {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .image-modal-overlay-wrapper {
    max-width: 98%;
    .ant-modal-body {
      padding: 0;
    }
  }
}