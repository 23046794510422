.ranking-opinion-form-element {
  &.multiple {
    .ranking-opinion-container {
      background-color: rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      padding: 1.75rem 2rem 1rem 2rem;

      .ranking-opinion-container-content {
        .ranking-opinion-container-error-text {
          color: @error-color;
        }

        .thermometer-form-element {
          padding: 0 0.5rem;
        }
      }
    }
  }
}