.image-rating-radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
  .ant-radio-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    min-height: 6rem;
    flex: 1 1 20%;
    text-align: center;
    min-width: 6rem;
    padding: 1rem 0.75rem;
  }
}

.image-rating-radio-content {
  display: flex;
  flex-direction: column;
}

.image-rating-radio-icon {
  color: @primary-color;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.image-rating-radio-title {
  font-size: 1rem;
  font-weight: 600;
}


@media only screen and (max-width: @screen-threshold-mobile) {
  .image-rating-radio-group {
    flex-direction: column;
    .ant-radio-button-wrapper {
      padding: 1rem 2rem;
      min-height: 4rem;

      &.ant-radio-button-wrapper-checked {
        .image-rating-radio-title {
          margin-left: 2.5rem;
        }
      }
    }
  }

  .image-rating-radio-icon {
    margin-bottom: 0px;
  }

  .image-rating-radio-content {
    flex-direction: row;
    align-items: center;
  }

  .image-rating-radio-title {
    margin-left: 2rem;
    transition: margin-left 0.25s ease-in-out;
  }
}